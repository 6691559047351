.ssbtp-type-selector-title {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    padding: 16px 16px 14px;
}

.ssbtp-type-selector-wrapper {
    .link-item {
        text-decoration: none;
    }

    .selector-item {
        cursor: pointer;
        display: flex;
        padding: 11px 16px;
        align-items: center;
        border-bottom: 1px solid #e4e4e4;
    }

    .selector-item-badge {
        width: 44px;
        height: 36px;
        margin-right: 16px;
        border-radius: 3px;
        color: #ffffff;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .selector-item-title {
        font-size: 16px;
        line-height: 19px;
        flex: 1;
        color: #333333;
    }
}

body.dark.normal {
    .selector-item-title {
        color: white;
    }
}
