.footer-wrapper {
    display: flex;
    padding: 16px 16px 3px;
    flex-direction: column;

    .menu-button {
        display: inline-flex;
        align-items: center;
        justify-content: flex-start;
        padding-right: 16px;
        cursor: pointer;
        margin-bottom: 13px;
    }

    .menu-button-label {
        margin-left: 12px;
    }
}
