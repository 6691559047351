.checkbox {
    width: 18px;
    height: 18px;
    border: 1px solid #dddddd;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;

    &.checked {
        border-color: #9D3ADC;
        background-color: #9D3ADC;
    }

    &.unchecked {
        border-color: #DDDDDD;
        background-color: transparent;
    }
}
