.stationSearchByTypePage-subTitleWrapper {
    display: flex;
    padding: 0 16px 12px;
    align-items: center;
    color: white;
    background-color: #9D3ADC;

    svg {
        height: 44px;
        margin-right: 6px;

        .fill {
            fill: white;
        }

        .fill-stroke {
            stroke: white;
        }
    }

    .back-button {
        display: flex;
        padding-right: 16px;
        height: 26px;
        align-items: center;
    }

    .type-title {
        font-size: 16px;
        font-weight: 600;
    }

    .type-title-badge {
        width: 44px;
        height: 26px;
        margin-right: 16px;
        border-radius: 3px;
        color: #ffffff;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

body.dark.normal {
    .stationSearchByTypePage-subTitleWrapper {
        background-color: #2F2F2F;
    }
}
