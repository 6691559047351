.ssbtp-routes-lists-subtitle {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    padding: 16px 16px 14px;
}

.ssbtp-routes-lists-list-wrapper {
    .list-item {
        display: flex;
        padding: 16px;
        border-bottom: 1px solid #e4e4e4;
    }

    .list-item-badge {
        width: 44px;
        height: 26px;
        margin-right: 16px;
        border-radius: 3px;
        color: #ffffff;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .list-item-routes {
        display: flex;
        flex-direction: column;
        flex: 1;
        padding-top: 3px;
    }

    .list-item-route {
        display: flex;
        align-items: center;
        justify-content: space-between;
        text-decoration: none;
        margin-bottom: 16px;
        color: #333333;

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    .list-item-route-name {
        font-size: 16px;
        line-height: 19px;
    }

    .icon-arrow-forward-wrapper {
        width: 9px;
        height: 14px;
        margin-left: 9px;
    }
}

body.dark.normal {
    .ssbtp-routes-lists-list-wrapper {
        .list-item-route {
            color: white;
        }
    }
}
