.drawer-wrapper {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    color: white;
    background-color: #9d3adc;
    overflow-y: auto;
    display: flex;
    flex-direction: column;

    svg {
        .fill-stroke {
            stroke: white;
        }

        .fill {
            fill: white;
        }
    }

    .head {
        height: 52px;
        min-height: 52px;
        display: flex;
        align-items: center;
    }

    .close-button {
        margin-left: 16px;
    }

    .menu-wrapper {
        display: flex;
        flex-direction: column;
        border-top-width: 1px;
        border-top-style: solid;
        border-top-color: rgba(255, 255, 255, 0.2);
        flex: 1;
    }

    .menu-filler {
        flex: 1;
    }

    .menu-item {
        color: white;
        text-decoration: none;
        display: flex;
        padding: 11px 16px;
        align-items: center;
        border-bottom-width: 1px;
        border-bottom-style: solid;
        border-bottom-color: rgba(255, 255, 255, 0.2);

        &.menu-bottom-item {
            border-bottom: 0 none;
            border-top-width: 1px;
            border-top-style: solid;
            border-top-color: rgba(255, 255, 255, 0.2);
        }
    }

    .menu-item-title {
        font-weight: 600;
        font-size: 18px;
        line-height: 21px;
        padding-right: 12px;
        flex: 1;
    }

    .menu-iIcon-wrapper {
        margin-right: 16px;
        height: 36px;
        display: flex;
        align-items: center;
    }

    .icon-marker-wrapper {
        position: absolute;
        bottom: 0;
        right: 0;

        svg {
            display: block;
        }
    }
}

body.dark.normal {
    .drawer-wrapper {
        background-color: #2f2f2f;
    }
}
