.sfp-grid-header-wrapper {
    padding-top: 8px;
    border-bottom: 1px solid;
    border-color: #e4e4e4;

    .title-text-menu {
        padding-left: 16px;
        display: grid;
        grid-template-columns: 67px 1fr 2.2fr;
        margin-bottom: 14px;
    }

    .button {
        &.disabled {
            color: #b8b8b8;
        }
    }
}

body.dark.normal {
    .sfp-grid-header-wrapper {
        border-color: rgba(255,255,255,0.2);
    }
}
