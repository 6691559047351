.accordition {
    .accordition-title {
        padding-top: 2px;
		display: flex;
		align-items: center;

        .accordition-icon {
            margin-left: 3px;
        }

        &.opened {
            .accordition-icon {
                transform: rotate(180deg);
            }
        }
    }
}
