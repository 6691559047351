.sfp-grid-forecast-card-wrapper {
    margin: 6px;
    padding: 6px;
    background: rgba(241, 241, 241, 0.3);
    border: 1px solid #dddddd;
    border-radius: 4px;

    &.sfp-grid-forecast-card-wrapper-on-final {
        background: rgba(241, 241, 241, 0.1);
        border-color: rgba(218, 218, 218, 0.3);
    }

    .transport-waiting-time {
        font-size: 16px;
    }

    .transports-icons {
        margin-top: 6px;

        svg + svg {
            margin-left: 8px;
        }
    }

    .transports-number {
        margin-top: 6px;
        font-size: 12px;
        color: #808080;
    }
}

.sfp-grid-forecast-card-no-data {
    margin: 6px;
	font-size: 16px;
	height: 34px;
	display: flex;
	align-items: center;
}

body.dark.normal {
    .sfp-grid-forecast-card-wrapper {
        background: rgba(255, 255, 255, 0.2);
        border-color: rgba(255, 255, 255, 0.5);

        &.sfp-grid-forecast-card-wrapper-on-final {
            background: rgba(241, 241, 241, 0.1);
            border-color: rgba(218, 218, 218, 0.3);
        }

        .transports-number {
            color: #b5b5b5;
        }
    }
}
