.primary-button {
    display: flex;
    justify-content: center;
    margin: 14px 16px;
    padding: 0;
    border: 2px solid #9D3ADC;
    border-radius: 4px;
    background: #9D3ADC;
    font-size: 20px;
    line-height: 44px;
    color: white;
    cursor: pointer;

    svg {
        height: 44px;
        margin-right: 6px;

        .fill {
            fill: white;
        }

        .fill-stroke {
            stroke: white;
        }
    }
}

.secondary-button {
    display: flex;
    justify-content: center;
    margin: 14px 16px;
    border: 2px solid #9D3ADC;
    border-radius: 4px;
    background: white;
    font-size: 20px;
    line-height: 44px;
    color: #9D3ADC;
}
