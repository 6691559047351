.ss-not-found-screen-wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 16px 16px 46px;

    .icon-wrapper {
        margin-bottom: 24px;
    }

    .content-text {
        width: 90%;
        text-align: center;
        font-size: 16px;
        line-height: 19px;
    }
}
