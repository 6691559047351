.setting-dialog {
    .settings-section {
        margin-bottom: 10px;

        .settings-section-title {
            font-size: 20px;
            font-weight: 600;
            padding: 6px;
            margin-bottom: 15px;
        }
    }
}
