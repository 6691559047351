.stationSearchByKeywordPage-searchFieldWrapper {
    display: flex;
    background-color: #9D3ADC;
    position: relative;

    svg {
        .fill {
            fill: #9D3ADC;
        }

        .fill-stroke {
            stroke: #9D3ADC;
        }
    }

    .search-input {
        flex: 1;
        margin: 0 16px 16px;
        padding: 0 10px;
        height: 36px;
        font-size: 16px;
        line-height: 19px;
        border-radius: 4px;
        border-width: 0;
		outline: none !important;
		font-family: 'SF Pro Display', 'ZoomIconFont';		
    }

    .icon-clear {
        position: absolute;
        right: 24px;
        top: 8px;
        cursor: pointer;
    }
}

body.dark.normal {
    .stationSearchByKeywordPage-searchFieldWrapper {
        background-color: #2F2F2F;

        svg {
            .fill {
                fill: #2F2F2F;
            }

            .fill-stroke {
                stroke: #2F2F2F;
            }
        }
    }
}
