.ssbtp-route-list-subtitle {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    padding: 16px 16px 14px;
}

.ssbtp-route-list-content {
    .list-item {
        display: flex;
        text-decoration: none;
        color: #333333;
    }

    .list-item-badge-wrapper {
        width: 17px;
        position: relative;
        margin-left: 16px;
        margin-right: 16px;

        &:before {
            content: '';
            width: 0;
            height: 101%;
            position: absolute;
            border-right: 1px solid transparent;
            //border-right-color: ${props => props.color};
            left: 8px;
        }

        &.first:before {
            top: 50%;
        }

        &.last:before {
            bottom: 40%;
        }
    }

    .list-item-badge-wrapper2 {
        position: absolute;
		top: 50%;
		z-index: 2;
    }

    .list-iItem-badge {
        top: -8px;
        position: relative;
        width: 17px;
        height: 17px;
        border: 4px solid transparent;
        border-radius: 50%;
        box-sizing: border-box;
        background: white;
    }

    .list-item-link {
        display: flex;
        justify-content: space-between;
        align-items: center;
        // margin-left: 16px;
        flex: 1;
        padding: 16px 16px 16px 0;
        border-bottom: 1px solid #e4e4e4;
    }

    .list-item-station-name {
        font-size: 16px;
        line-height: 19px;
    }

    .icon-arrow-forward-wrapper {
        width: 9px;
        height: 14px;
        margin-left: 9px;
    }
}

body.dark.normal {
    .ssbtp-route-list-content {
        .list-item {
            color: white;
        }

        .list-iItem-badge {
            background: #474747;
        }
    }
}
