.ss-title-wrapper {
    display: flex;
    padding: 0 16px 16px 16px;
    align-items: center;
    color: white;
    background-color: #9D3ADC;

    .title {
        font-size: 20px;
        font-weight: 600;
    }
}

body.dark.normal {
    .ss-title-wrapper {
        background-color: #2F2F2F;
    }
}
