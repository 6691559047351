.sfp-view-mode-wrapper {
    display: flex;
    padding: 8px 6px 0;
    background-color: white;

    .link {
        display: flex;
        align-items: center;
        padding: 10px;
        line-height: 19px;
        color: #333333;
        font-size: 16px;
        text-decoration: none;
    }

    .label {
        padding: 0 0 0 8px;
        display: inline;
        vertical-align:text-bottom;
        line-height: 19px;
    }
}

body.dark.normal {
    .sfp-view-mode-wrapper {
        background-color: #474747;

        .link {
            color: white;
        }
    }
}
