.dialog-line-checkbox {
    display: grid;
    grid-template-columns: 32px 27px 1fr;
    padding: 0 6px;
    align-items: center;
    margin-bottom: 19px;

    .text {
        color: #333333;
        font-size: 16px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
}

body.dark.normal {
    .dialog-line-checkbox {
        .text {
            color: white;
        }
    }
}
