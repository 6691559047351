.sfp-station-info-wrapper {
    display: grid;
    grid-template-columns: 62px 4fr;
    padding: 0 16px 16px;
    color: white;
    background-color: #9D3ADC;

    .text-wrapper {
        display: grid;
        justify-content: flex-start;
    }

    .title {
        display: grid;
        font-size: 20px;
        justify-content: flex-start;
        align-items: center;
        font-weight: 700;
    }

    .description {
        display: grid;
        justify-content: flex-start;
        align-items: center;
    }
}

body.dark.normal {
    .sfp-station-info-wrapper {
        background-color: #2F2F2F;
    }
}
