.ss-station-list-wrapper {
    .list-item {
        display: flex;
        padding: 16px;
        border-bottom: 1px solid #e4e4e4;
        align-items: center;
        text-decoration: none;
    }

    .list-item-icon-wrapper {
        margin-right: 16px;
        margin-left: -3px;
    }

    .list-item-name-wrapper {
        display: flex;
        flex-direction: column;
        flex: 1;
    }

    .list-item-name {
        color: #333333;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
    }

    .list-item-description {
        color: #333333;
        font-size: 14px;
        line-height: 17px;
    }

    .list-item-distance {
        color: #333333;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        width: 50px;
        text-align: center;
    }
}

body.dark.normal {
    .ss-station-list-wrapper {
        .list-item-icon-wrapper {
            margin-left: -1px;
        }
        .list-item-name {
            color: white;
        }

        .list-item-description {
            color: white;
        }

        .list-item-distance {
            color: white;
        }
    }
}
