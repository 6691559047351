.sfp-filter-group {
    margin: 0 16px 24px;
    text-align: left;

    .title {
        font-size: 20px;
        margin-bottom: 15px;
    }

    .line {
        display: grid;
        grid-template-columns: 32px 1fr;
        align-items: center;
        margin-bottom: 19px;
    }

    .routes-type-line {
        display: flex;
        margin-bottom: 19px;

        .accordition {
            margin-left: 5px;

            .transport-type-title {
                font-size: 16px;
                line-height: 18px;
            }
        }

        .sfp-rf-group {
            display: flex;
            flex-wrap: wrap;

            .sfp-rf-group-item {
                width: 40px;
                height: 40px;
                border-radius: 7px;
                border-width: 2px;
                border-style: solid;
                display: flex;
                align-items: center;
                justify-content: center;
                text-transform: uppercase;
                padding: 7px;
                box-sizing: border-box;
                margin-right: 9px;
                margin-top: 9px;
                cursor: pointer;
            }

            .sfp-rf-group-item.excepted {
                border-color: #f0f0f0;
            }
        }
    }
}
