.checkbox.checked {
    &.А {
        border-color: #ff4238;
        background-color: #ff4238;
    }

    &.Ав {
        border-color: #ff4238;
        background-color: #ff4238;
    }

    &.Т {
        border-color: #64b2bc;
        background-color: #64b2bc;
    }

    &.Тб {
        border-color: #64b2bc;
        background-color: #64b2bc;
    }

    &.М {
        border-color: #fdbf35;
        background-color: #fdbf35;
    }

    &.Мт {
        border-color: #fdbf35;
        background-color: #fdbf35;
    }

    &.Тр {
        border-color: #4bba52;
        background-color: #4bba52;
    }

    &.Тм {
        border-color: #4bba52;
        background-color: #4bba52;
    }
}

.sfp-forecast-row-number {
    &.А {
        background-color: #ff4238;
    }

    &.Ав {
        background-color: #ff4238;
    }

    &.Т {
        background-color: #64b2bc;
    }

    &.Тб {
        background-color: #64b2bc;
    }

    &.М {
        background-color: #fdbf35;
    }

    &.Мт {
        background-color: #fdbf35;
    }

    &.Тр {
        background-color: #4bba52;
    }

    &.Тм {
        background-color: #4bba52;
    }
}

.stationSearchByTypePage-subTitleWrapper .type-title-badge {
    &.А {
        background: #ff4238;
    }

    &.Ав {
        background: #ff4238;
    }

    &.Т {
        background: #64b2bc;
    }

    &.Тб {
        background: #64b2bc;
    }

    &.М {
        background: #fdbf35;
    }

    &.Мт {
        background: #fdbf35;
    }

    &.Тр {
        background: #4bba52;
    }

    &.Тм {
        background: #4bba52;
    }
}

.ssbtp-type-selector-wrapper .selector-item-badge {
    &.А {
        background: #ff4238;
    }

    &.Ав {
        background: #ff4238;
    }

    &.Т {
        background: #64b2bc;
    }

    &.Тб {
        background: #64b2bc;
    }

    &.М {
        background: #fdbf35;
    }

    &.Мт {
        background: #fdbf35;
    }

    &.Тр {
        background: #4bba52;
    }

    &.Тм {
        background: #4bba52;
    }
}

.ssbtp-route-list-content .list-item-badge-wrapper {
    &.А {
        &:before {
            border-right-color: #ff4238;
        }

        .list-iItem-badge {
            border-color: #ff4238;
        }
    }

    &.Ав {
        &:before {
            border-right-color: #ff4238;
        }

        .list-iItem-badge {
            border-color: #ff4238;
        }
    }

    &.Т {
        &:before {
            border-right-color: #64b2bc;
        }

        .list-iItem-badge {
            border-color: #64b2bc;
        }
    }

    &.Тб {
        &:before {
            border-right-color: #64b2bc;
        }

        .list-iItem-badge {
            border-color: #64b2bc;
        }
    }

    &.М {
        &:before {
            border-right-color: #fdbf35;
        }

        .list-iItem-badge {
            border-color: #fdbf35;
        }
    }

    &.Мт {
        &:before {
            border-right-color: #fdbf35;
        }

        .list-iItem-badge {
            border-color: #fdbf35;
        }
    }

    &.Тр {
        &:before {
            border-right-color: #4bba52;
        }

        .list-iItem-badge {
            border-color: #4bba52;
        }
    }

    &.Тм {
        &:before {
            border-right-color: #4bba52;
        }

        .list-iItem-badge {
            border-color: #4bba52;
        }
    }
}

.ssbtp-routes-lists-list-wrapper .list-item-badge {
    &.А {
        background: #ff4238;
    }
    &.Ав {
        background: #ff4238;
    }

    &.Т {
        background: #64b2bc;
    }
    &.Тб {
        background: #64b2bc;
    }

    &.М {
        background: #fdbf35;
    }
    &.Мт {
        background: #fdbf35;
    }

    &.Тр {
        background: #4bba52;
    }
    &.Тм {
        background: #4bba52;
    }
}

.icon-station {
    .А {
        fill: #ff4238;
    }
    .Ав {
        fill: #ff4238;
    }

    .Т {
        fill: #64b2bc;
    }
    .Тб {
        fill: #64b2bc;
    }

    .М {
        fill: #fdbf35;
    }
    .Мт {
        fill: #fdbf35;
    }

    .Тр {
        fill: #4bba52;
    }
    .Тм {
        fill: #4bba52;
    }
}

.sfp-rf-group-item {
    &.А {
        border-color: #ff4238;
    }

    &.Ав {
        border-color: #ff4238;
    }

    &.Т {
        border-color: #64b2bc;
    }

    &.Тб {
        border-color: #64b2bc;
    }

    &.М {
        border-color: #fdbf35;
    }

    &.Мт {
        border-color: #fdbf35;
    }

    &.Тр {
        border-color: #4bba52;
    }

    &.Тм {
        border-color: #4bba52;
    }
}
