svg {
    .icon-dot {
        fill: white;
        stroke: white;
    }
}

body.dark.normal {
    svg {
        .icon-dot {
            fill: #474747;
            stroke: #474747;
        }
    }
}
